
@font-face {
  font-family: header;
  src: url('header.otf');
}
* {
  font-family: 'Raleway', sans-serif;
}
.headerText {
  font-family: header!important;
}
body {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
.scPage::-webkit-scrollbar {
  width: 0!important;
}
input, textarea, select, button, Input, Button, Select {
	outline: none!important;
  resize: none!important;
}

.updown {
  animation: updownEffect 2s infinite;
  transition: 300ms ease-in-out;
}

@keyframes updownEffect {
  0% {
    transform: translateY(8px);
  }
  50% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(8px);
  }
}

input[type=radio] {
  border: 0px;
  width: 22px;
  height: 22px;
}

.noscbar::-webkit-scrollbar {
  display: none!important;
}
.noscbar {
  scrollbar-width: none!important;
}




.card-field {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
  height: 40px;
  background: white;
  font-size: 17px;
  color: #3a3a3a;
  font-family: helvetica, tahoma, calibri, sans-serif;
}

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.mark {
  display: flex;
  align-items: center;
}
.spinner {
  display: inline-block;
  height: 40px;
  width: 40px;
  box-sizing: border-box;
  border: 3px solid rgba(0, 0, 0, 0.2);
  border-top-color: rgba(33, 128, 192, 0.8);
  border-radius: 100%;
  animation: rotation 0.7s infinite linear;
}

.spinner.tiny {
  height: 20px;
  width: 20px;
  border-top-color: #007bff;
  position: relative;
  top: 3px;
}

@keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(359deg);
  }
}



.bounce2 {
  animation: bounce2 2s ease infinite;
  -webkit-animation: bounce2 2s ease infinite;
  -moz-animation: bounce2 2s ease infinite;
  }
  @keyframes bounce2 {
    0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
    40% {transform: translateY(-30px);}
    60% {transform: translateY(-15px);}
  }

  @-webkit-keyframes bounce2 {
    0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
    40% {transform: translateY(-30px);}
    60% {transform: translateY(-15px);}
  }

  @-moz-keyframes bounce2 {
    0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
    40% {transform: translateY(-30px);}
    60% {transform: translateY(-15px);}
  }

  .swing {
    transform-origin: top center;
    animation: swing 3s ease infinite;
    -webkit-animation: swing 3s ease infinite;
    -moz-animation: swing 3s ease infinite;
  }
  @keyframes swing {
    20% { transform: rotate(18deg); }	
    40% { transform: rotate(-13deg); }
    60% { transform: rotate(8deg); }	
    80% { transform: rotate(-8deg); }	
    100% { transform: rotate(0deg); }
  }
  @-webkit-keyframes swing {
    20% { transform: rotate(18deg); }	
    40% { transform: rotate(-13deg); }
    60% { transform: rotate(8deg); }	
    80% { transform: rotate(-8deg); }	
    100% { transform: rotate(0deg); }
  }
  @-moz-keyframes swing {
    20% { transform: rotate(18deg); }	
    40% { transform: rotate(-13deg); }
    60% { transform: rotate(8deg); }	
    80% { transform: rotate(-8deg); }	
    100% { transform: rotate(0deg); }
  }




.chevron-down {
  margin-left: auto;
  transition: transform 0.2s ease-in-out;
}

.szh-accordion {
  &__item {
    border-bottom: 1px solid rgb(202, 202, 205);

    &-btn {
      cursor: pointer;
      display: flex;
      align-items: center;
      width: 100%;
      margin: 0;
      padding: 2rem 0;
      font-weight: 400;
      text-align: left;
      color: #000;
      font-family: font;
      font-size: 18px;
      background-color: transparent;
      border: none;
    }

    &-content {
      transition: height 0.2s ease-in-out;
    }

    &-panel {
      padding: 0 0 2rem 0;
      color: rgb(100,100,100);
    }

    &--expanded {
      .szh-accordion__item-btn {
        background-color: transparent;
      }
      .chevron-down {
        transform: rotate(180deg);
      }
    }
  }
}